<template>
    <div>
        <h2>绑定手机</h2>
    </div>
</template>

<script>
export default {
name: "BindMobile"
}
</script>

<style scoped>

</style>